import {h,render,Component} from "preact";
import '../leaflet.css';
import L from '../leaflet.js';
import data from '../data.json';
import "../map.css";

export default class Map extends Component {

  componentDidMount() {

  var layerC = "#06c";

  function onEachFeature(feature, layer) {
        // does this feature have a property named popupContent?
        if (feature.properties && feature.properties.provincia) {
            layer.bindPopup("<b>Provincia:</b> " + feature.properties.provincia);
            layer.setStyle({
              "fillColor": layerC,
              "fillOpacity": 0.1
            });
        }
        var res = province.find( result => result === feature.properties.provincia );
        if(res!==undefined){
          var props = list.find( element => element.provincia === feature.properties.provincia );
          if(props.provincia === "Trento") {
            props.regione = "Trentino-Alto Adige";
          }
          if(props.provincia === "Mantova") {
            props.regione = "Lombardia";
          }
          layer.bindPopup("<b>Provincia:</b> " + feature.properties.provincia + "<br>" + "<b>Regione:</b> "+props.regione +
          "<br>" + "<b>Denominazione:</b><ul>"+props.olio+"</ul>");
          var c;

          switch(props.count) {
            case 1: c = 0.3;
            break;
            case 2: c = 0.6;
            break;
            case 3: c = 0.9;
            break;
            default: c = 0.3;
            }
          layer.setStyle({
            "fillColor": layerC,
            "fillOpacity": c
          });
        }
    }

  var json = data;
  console.log(json);

  var list = [];
  var province = [];

  for (var i = 0; i < json.length; i++) {
  var filter = json[i].Provincia;
  var den = json[i].Denominazione;
  var cat = json[i].Categoria;
  var reg = json[i].Regione;
  var result = filter.split(", ");
    for (var z = 0; z < result.length; z++) {
      // build new object within loop to push new objects to array
      var objectList = {};

      var search = list.find( el => el.provincia === result[z] );
      var t = list.findIndex((element) => element.provincia === result[z])
      if (search && t!==undefined) {
        list[t].provincia = result[z];
        list[t].regione = reg;
        list[t].olio += "<li>" + den + " " + cat + "</li>";
        list[t].count += 1;
        province.push(result[z]);
      } else {
        objectList.provincia = result[z];
        objectList.regione = reg;
        objectList.olio = "<li>" + den + " " + cat + "</li>";
        objectList.count = 1;
        list.push(objectList);
        province.push(result[z]);
      }
    }
  }

  var setProvince = new Set(province);
  var province = [...setProvince];

  //console.log(list);
  //console.log(province);

  var map = new L.Map('map');
  document.getElementById('map').style.opacity = 1;
  var mapbox = new L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: 'mapbox/light-v10',
    tileSize: 512,
    zoomOffset: -1,
    accessToken: process.env.MAPBOX_TOKEN
  }).addTo(map);
  var rome = new L.LatLng(41.890251, 12.492373);

  var myStyle = {
  "color": layerC,
  "weight": 1,
  "opacity": 1
  };

  var geoJsonUrl = process.env.GEOJSON
  $.get( geoJsonUrl, function( data ) {
    map.setView(rome, 6).addLayer(mapbox);
    L.geoJSON(data.features,{onEachFeature: onEachFeature,style: myStyle}).addTo(map);
    document.getElementById('map').classList.remove('loading');
    notificationShow('not2',6000);
  }, "json");

  }

  render() {
    return (<div id="map" class="loading mt-4">
        			<div class="notification with-icon" role="alert" aria-labelledby="not2-title" id="not2" style="z-index: 9999;">
        				<h5 id="not2-title">
<svg class="icon" style="top:0;">
  <g>
    <path
      d="M17.1,7.9l.7.7L10,16.2,5.3,11.6l.7-.7,4,3.9ZM22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12Zm-1,0a9,9,0,1,0-9,9A9,9,0,0,0,21,12Z"
    />
  </g>
  <g>
    <rect width="24" height="24" fill="none"/>
  </g>
</svg>
    Map has loaded successfully.</h5>
        			</div>
    		</div>
);
  }

}
