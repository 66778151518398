import { h, Component } from "preact";
import Header from "./header.jsx";
import "../code.css";
import json from '../data.json';

export default class Docs extends Component {
  apiGet() {
    var path = document.getElementById('path').value;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://api.iloveolive.it'+path, true);
    xhr.responseType = "json";
    xhr.send();
    xhr.onerror = () => {
      document.getElementById('code').innerHTML = 'Something went wrong. Please check internet connection or API URI';
    }
    xhr.onload = () => {
      document.getElementById('code').innerHTML = JSON.stringify(xhr.response, null, 2);
    }
  }

  render() {
    return (
    <div id="docs">
      <div class="p-3 m-3">
        <h1>docs</h1>
        <h4 class="mt-3">list of attributes</h4>
        <tr><td><samp>id</samp></td><td><p>Id of the record.</p></td></tr>
        <tr><td><samp>Provincia</samp></td><td><p>One or more provinces where the olive oil type is partly or fully located.</p></td></tr>
        <tr><td><samp>Data pubblicazione</samp></td><td><p>Publication date in the Official Journal of the European Union.</p></td></tr>
        <tr><td><samp>Categoria</samp></td><td><p>Two possible values for the category of certificate: <samp>D.O.P</samp> or <samp>I.G.P</samp></p></td></tr>
        <tr><td><samp>Denominazione</samp></td><td><p>Name of the olive oil type.</p></td></tr>
        <tr><td><samp>Regione</samp></td><td><p>One or more regions where the olive oil type is partly or fully located.</p></td></tr>
        <h4 class="mt-3">methods</h4>
        <p><span class="complementary-2-color">GET </span><samp class="text-400 text-decoration-none">/oliveoils/</samp><br/>retrieve complete list of olive oil objects</p>
        <p><span class="complementary-2-color">GET </span><samp class="text-400 text-decoration-none">/oliveoils/&#123;id&#125;</samp><br/>retrieve one olive oil object</p>
        <h6 class="my-3">API endpoint: <samp class="text-400 text-decoration-none font-weight-light">https://api.iloveolive.it</samp></h6>
        <h6 class="my-3">Content-Type: <samp class="text-400 text-decoration-none font-weight-light">json</samp></h6>
        </div>
        <p class="text-info mx-md-5 mt-0">Use the playground below to test the API. You can type in your own path, e.g. <samp class="text-decoration-none">/oliveoils/32</samp></p>
        <figure class="highlight mx-md-5 mt-0 mb-5">
          <div class="api p-3 d-block d-sm-flex">
            <div class="call">
              <span class="align-middle complementary-2-color">GET </span><input class="align-middle" id="path" value="/oliveoils/1"></input>
            </div>
            <button type="button" class="btn btn-secondary" onclick={this.apiGet}>SEND</button>
          </div>
        <pre class="p-3">
          <code id="code">Click the SEND button to see the JSON response.
          </code>
        </pre>
      </figure>
    </div>
  )
  }
}
