import {h,render,Component} from "preact";
import Header from "./header.jsx";
import Docs from "./docs.jsx";
import Map from "./map.jsx";

const Footer = () => {
	return (
		<footer class="it-footer">
		  <div class="it-footer-small-prints clearfix">
		    <div class="container">
		      <h3 class="sr-only">Sezione Link Utili</h3>
		      <ul class="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
		        <li class="list-inline-item">
		          <a href="#" title="Note Legali">Media policy</a>
		        </li>
		        <li class="list-inline-item">
		          <a href="#" title="Note Legali">Note legali</a>
		        </li>
		        <li class="list-inline-item">
		          <a href="#" title="Privacy-Cookies">Privacy policy</a>
		        </li>
		        <li class="list-inline-item">
		          <a href="#" title="Mappa del sito">Mappa del sito</a>
		        </li>
		      </ul>
		    </div>
		  </div>
		</footer>
	)
}

export default class App extends Component {

	render() {
    return (
    <div>
      <Header />
      <div class="container">
				<div class="px-3 mx-3 mt-4 text-center">
				<h4>olive oil REST API</h4>
				<p>Find all D.O.P and I.G.P olive oils currently registered for Italy in the Official Journal of the European Union.</p>
				<p class="text-info mb-0">Click on the map to see different types of olive oil per province. Scroll down to test the API.</p>
				</div>
        <Map />
        <Docs />
      </div>
			<Footer />
    </div>)
	}
}
