import { h, render } from 'preact';

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'bootstrap-italia/dist/js/bootstrap-italia.bundle.min.js';
import "./scss/bootstrap-italia-custom.scss";
import App from "./components/app.jsx";

import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

render(<App />, document.getElementById('app'));
