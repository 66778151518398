import {h,Component} from "preact";
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';

export default class Header extends Component {
	activeToggle(e) {
		console.log(e.target);
		e.target.parentNode.classList.add('active');
		document.getElementsByClassName('active')[0].classList.remove('active');
	}

	render() {
    return (
			<div class="it-header-navbar-wrapper">
			  <div class="primary-bg">
			    <div class="row">
			      <div class="col-12">
			        <nav class="navbar navbar-expand-lg">
			          <button class="custom-navbar-toggler" type="button" aria-controls="nav1" aria-expanded="false" aria-label="Toggle navigation" data-target="#nav1">
									<span class="navbar-toggler-icon"></span>
			          </button>
			          <div class="navbar-collapsable" id="nav1" style="display: none;">
			            <div class="overlay" style="display: none;"></div>
			            <div class="close-div sr-only">
			              <button class="btn close-menu" type="button"><span class="it-close"></span>close</button>
			            </div>
			            <div class="menu-wrapper justify-content-center">
			              <ul class="navbar-nav">
			                <li class="nav-item"><a class="nav-link active" href="/" onClick={this.activeToggle}><h2 class="m-0">iloveolive </h2><span class="sr-only">current</span></a></li>
			                <li class="nav-item"><a class="nav-link" href="#docs" onClick={this.activeToggle}><span>docs </span></a></li>
			                <li class="nav-item"><a class="nav-link" href="github.com/cintogia" target="_blank"><span>Github</span></a></li>
			              </ul>
			            </div>
			          </div>
			        </nav>
					</div>
			    </div>
			  </div>
			</div>
    )
	}
}
